import { defineStore } from 'pinia'
import { initializeApp } from 'firebase/app'
import {
  initializeAppCheck,
  getToken,
  ReCaptchaEnterpriseProvider,
} from 'firebase/app-check'

let recaptchaKey = import.meta.env.VITE_RECAPTCHA_KEY

export const useAppStore = defineStore('app', {
  state: () => ({
    appCheck: null,
    fireApp: null,
    provider: null,
    title: null,
    formResponse: null,
  }),

  getters: {
    // Appcheck Token
    async token() {
      if (recaptchaKey == 'none') return 'none'

      let token = await getToken(this.appCheck, true)
      return token.token
    },

    // Features
    hasSigninWithApple() {
      return false
    },
  },

  actions: {
    initialize() {
      if (this.fireApp) return
      // only init once!

      const appConfig = JSON.parse(import.meta.env.VITE_APP_FIREBASE)
      this.fireApp = initializeApp(appConfig)

      if (recaptchaKey == 'none') return
      let provider = new ReCaptchaEnterpriseProvider(recaptchaKey)
      this.provider = provider

      let appcheckConfig = {
        provider: provider,
        isTokenAutoRefreshEnabled: true,
      }

      try {
        if (localStorage.getItem('test_appcheck')) {
          self.FIREBASE_APPCHECK_DEBUG_TOKEN =
            localStorage.getItem('test_appcheck')
        } else if ('VITE_TEST_APP_CHECK' in import.meta.env) {
          self.FIREBASE_APPCHECK_DEBUG_TOKEN =
            import.meta.env.VITE_TEST_APP_CHECK
        }
        console.debug(
          'TEST_TOKEN',
          self.FIREBASE_APPCHECK_DEBUG_TOKEN || 'skip'
        )
        this.appCheck = initializeAppCheck(this.fireApp, appcheckConfig)
      } catch (err) {
        console.log(err)
      }
    },
  },
})
